import { buildAuthFunctions } from '../helpers.js';

const { create, edit, manage, delete: canDelete } = buildAuthFunctions('people');

export default {
    changeOwner: manage,
    see: () => true,
    create,
    edit: (person, user) => !_.has(person, 'meta.connected') && edit(person, user),
    manage: (person, user) => !_.has(person, 'meta.connected') && manage(person, user),
    delete: (person, user) => !_.has(person, 'meta.connected') && canDelete(person, user),
};