import $ from 'jquery';
import _ from 'lodash';

// start Timeout issue fix
const sessionTime = (1000 * 60 * 60 * 2) + 5000; // 2 hours + 5 seconds

let timeoutId;

const isLoggedIn = window.nal?.user?.id || window.nal?.store?.getters?.loggedIn;
const isOutlookProject = window.nal?.project === 'outlook';

function logout() {
    if (isLoggedIn && window.nal?.project !== 'outlook') {
        let path = '/login?session_expired=true';
        if (_.get(window.nal, 'user.role') === 'partner') {
            path = '/logout';
            localStorage.setItem('showSessionTimedOutMsg', true.toString());
        }
        window.location.replace(path);
    } else if (isLoggedIn) {
        window.location.reload(true);
    }
}

function resetTimer() {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(checkAuthenticated, sessionTime);
}

function checkAuthenticated() {
    $.get('/auth/check').then(({ authenticated }) => {
        if (!authenticated) {
            logout();
        }
    });
}

const refreshSession = _.debounce(function () {
    let timeout = window.nal?.user?.isImpersonating ? 500 : 0;
    setTimeout(() => {
        $.get('/auth/refresh').catch(e => {
            if (e.status === 401) {
                return logout();
            }
            return Promise.reject(e);
        });
    }, timeout);
}, 1000 * 60 * 10, { leading: true, trailing: false });

function setLandingTimer() {
    // window.setTimeout(refreshLandingSession, sessionTime);
    window.setTimeout(refreshLandingSession, sessionTime);
}

function refreshLandingSession() {
    location.reload();
}

if (!isOutlookProject) {
    if (isLoggedIn) {
        resetTimer();

        $(document).ajaxComplete(resetTimer);

        ['touchstart', 'mousedown', 'keydown', 'focus'].forEach((event) => {
            window.addEventListener(event, refreshSession, false);
        });
    } else {
        setLandingTimer();
    }
}
