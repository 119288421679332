function getToken() {
    return window.EzekiaLocalStorage.get('extAuthToken');
}

function getImpersonateToken() {
    return window.EzekiaLocalStorage.get('extImpersonateAuthToken');
}

function handleOutlookRequest() {
    if (window.nal.project === 'outlook') {
        $.ajaxSetup({
            beforeSend: function (xhr, settings) {
                const token = getToken();
                const impersonateToken = getImpersonateToken();

                if (token) {
                    if (impersonateToken) {
                        xhr.setRequestHeader('Authorization', 'Bearer ' + impersonateToken);
                    } else {
                        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                    }
                }

                const apiVersion = settings.url.match(/api\/(v\d+\/)?/g);
                if (apiVersion && settings.url.indexOf(`${apiVersion}outlook`) === -1) {
                    settings.url = settings.url.replace(apiVersion, `${apiVersion}outlook/`);
                }
            },
            complete: function (xhr) {
                const token = xhr.getResponseHeader('X-New-Token');
                if (token) {
                    window.EzekiaLocalStorage.set('extAuthToken', token);
                }
            },
        });
    }
}

function addHeaderToSettings(settings) {
    if (window.nal.project === 'outlook') {
        let token = window.jwt.getToken();
        let impersonateToken = window.jwt.getImpersonateToken();

        if (token) {
            if (impersonateToken) {
                settings.header = {
                    'Authorization': 'Bearer ' + impersonateToken,
                };
            } else {
                settings.header = {
                    'Authorization': 'Bearer ' + token,
                };
            }
        }
    }

    return settings;
}

export default {
    getToken,
    getImpersonateToken,
    handleOutlookRequest,
    addHeaderToSettings,
};
