import EzekiaLocalStorage from '@js/core/EzekiaLocalStorage.js';
import Analytics from '@js/core/cookies/analytics.js';
import Functional from '@js/core/cookies/functional.js';

export function getPreferences() {
    return JSON.parse(EzekiaLocalStorage.get('cookie-preferences'));
}

export function isEnabled(type) {
    let preferences = getPreferences();
    return preferences && preferences[type];
}

export function setPreferences(data) {
    EzekiaLocalStorage.set('cookie-preferences', JSON.stringify(data));
    data.analytics ? Analytics.optIn() : Analytics.optOut();
    data.functional ? Functional.optIn() : Functional.optOut();
}
