import { buildAuthFunctions } from '../helpers.js';

const authFunctions = buildAuthFunctions('assignments');

export default {
    archive: authFunctions.delete,
    recover: authFunctions.delete,
    changeOwner: authFunctions.manage,
    ...authFunctions,
};
