function policyBuilder() {
    let policies = {};
    let policyFiles;

    switch (window.packageBuild) {
        case 'cp':
            policyFiles = import.meta.glob('./client-portal/**/*.js', { eager: true });
            break;
        case 'nal':
            policyFiles = import.meta.glob('./search/**/*.js', { eager: true });
            break;
        default:
            policyFiles = import.meta.glob('./search/**/*.js', { eager: true });
    }

    for (const [file, module] of Object.entries(policyFiles)) {
        if (file.endsWith('/policies.js') || file.endsWith('/helpers.js')) {
            continue;
        }
        const policyName = file.match(/[^/]+(?=\.js$)/)[0];
        policies[policyName] = module.default;
    }

    return policies;
}

export {
    policyBuilder,
};
