import { nextTick } from 'vue';

class Errors {
    /**
     * Create a new Errors instance.
     */
    constructor(form) {
        if (form) {
            this.form = form;
        }
        this.errors = {};
        this.elements = {};
    }

    /**
     * Determine if an errors exists for the given field.
     *
     * @param {string|RegExp} field
     */
    has(field) {
        if (field instanceof RegExp) {
            return Object.keys(this.errors).some(key => field.test(key));
        }
        return this.errors.hasOwnProperty(field);
    }

    /**
     * Determine if we have any errors.
     */
    any() {
        return Object.keys(this.errors).length > 0;
    }

    /**
     * Returns all possible errors.
     */
    all() {
        return this.errors;
    }

    /**
     * Retrieve the error message for a field.
     *
     * @param {string} field
     */
    get(field) {
        if (this.errors[field]) {
            return typeof this.errors[field] === 'object' ? this.errors[field][0] : this.errors[field];
        }
    }

    /**
     * Retrieve the error messages for a field and concatinate them to a string.
     *
     * @param {string} field
     */
    getMultiple(field) {
        if (this.errors[field]) {
            if (typeof this.errors[field] === 'object' && field === 'password') {
                if (this.errors[field].length > 1) {
                    return this.errors[field].join(' ');
                }
                return this.errors[field][0];
            }
            return this.errors[field];
        }
    }

    /**
     * Retrieve the error message for a field.
     *
     * @param {string} field
     */
    getNested(field) {
        const fields = field.split('.');
        let error = this.errors;

        for (const prop in fields) {
            if (typeof error === 'object') {
                error = error[fields[prop]] ?? '';
            }
        }

        return error;
    }

    /**
     * Add a new error message if one doesn't already exist.
     *
     * @param {string} field
     * @param error
     */
    add(field, error) {
        if (!this.has(field)) {
            this.errors[field] = error;
        }
    }

    /**
     * Record the new errors.
     *
     * @param {object} errors
     */
    record(errors, timeout = 3000) {
        this.errors = errors;
        if (timeout) {
            window.setTimeout(() => {
                this.clear();
            }, timeout);
        }
    }

    /**
     * Clear one or all error fields.
     *
     * @param {string|null} field
     */
    clear(field = null) {
        if (field) {
            delete this.errors[field];

            return;
        }

        this.errors = {};
    }

    addElement(key, el, useAlert) {
        this.elements[key] = { el, useAlert };
    }

    scrollToFirst(useScrollTo = false, options = null) {
        options = options || { behavior: 'smooth', inline: 'center' };
        nextTick(() => {
            for (let key in this.elements) {
                let rx = new RegExp(key.replace('*', '.*'));
                if (Object.keys(this.errors).some(key => rx.test(key))) {
                    let { el, useAlert } = this.elements[key];
                    if (useAlert) {
                        el = el.getElementsByClassName('c-alert-tooltip')[0] || el;
                    }
                    if (useScrollTo && window.scrollTo) {
                        scrollToElement(el);
                    } else {
                        el.scrollIntoView(options);
                    }
                    break;
                }
            }
        });
    }
}

export default Errors;

function scrollToElement(el) {
    const elOffset = el.getBoundingClientRect().top - 60;
    const scrollY = document.documentElement.scrollTop || window.scrollY || 0;
    const focalPoint = window.innerHeight / 3;
    const outOfFocus = elOffset > focalPoint || elOffset < 0;
    if (outOfFocus) {
        const offset = elOffset + scrollY - focalPoint;
        window.scrollTo({ top: Math.max(offset, 0), behavior: 'smooth' });
    }
}
