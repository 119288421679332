import handleNalError from './handleNalError.js';
import _ from 'lodash';
import Errors from './Errors';

const maxUploadSize = (typeof window.maxUploadSize === 'undefined') ? 20 : window.maxUploadSize;
window.maxUploadSize = maxUploadSize;

/**
 * Check if the value is a file
 *
 * @return {Boolean}
 */
function isFile(val) {
    const newBlob = new Blob;
    return val && (val.constructor === File || val.constructor === Blob || val.constructor === newBlob.constructor);
}

function isTooBig(val, maxSize) {
    let isBig = false;

    if (_.isArray(val)) {
        for (const file of val) {
            if (isFile(file) && file.size >= (maxSize * 1024 * 1024)) {
                isBig = true;
            }
        }
    }

    return isBig;
}

function getFileFromEvent(event, multiFile) {
    if (isFile(event)) {
        return event;
    }

    if (!multiFile) {
        return event.target.files
            ? event.target.files[0]
            : event.dataTransfer?.files[0]
        ;
    }

    return event.target.files
        ? Array.from(event.target.files)
        : Array.from(event.dataTransfer?.files)
    ;
}

class Form {
    /**
     * Create a new Form instance.
     *
     * @param {object} data
     */
    constructor(data) {
        this.maxSize = maxUploadSize;

        this.originalData = data;

        this.setData(data);

        this.errors = new Errors();
    }

    /**
     * Add a new key to the form
     *
     * @param  {string} key
     * @param  {mixed} value
     */
    append(key, value) {
        this.originalData[key] = value;
        this[key] = value;
    }

    formatData(cb) {
        this.formatDataCallback = cb;
        return this;
    }
    /**
     * Remove a key from the form
     *
     * @param  {string} key
     */
    remove(key) {
        delete this.originalData[key];
        delete this[key];
    }

    /**
     * Merge a whole object into the form
     *
     * @param {object} data
     */
    appendData(data) {
        for (let field in data) {
            this.append(field, data[field]);
        }
    }

    /**
     * Add a new key to the form that doesn't get cleared
     *
     * @param  {string} key
     * @param  {mixed} value
     */
    appendConstant(key, value) {
        this.constantDataValues[key] = value;
        this[key] = value;
    }

    /**
     * Add data to be sent with every request
     *
     * @param {object} data
     */
    constantData(data) {
        this.constantDataValues = data;

        this.setData(data);

        return this;
    }

    /**
     * Add data to the form object
     *
     * @param {object} data
     */
    setData(data) {
        for (let field in data) {
            this[field] = data[field];
        }
    }

    /**
     * Fetch all relevant data for the form.
     */
    data() {
        let data = {};

        for (let property in this.originalData) {
            data[property] = this[property];
        }
        for (let property in this.constantDataValues) {
            data[property] = this[property];
        }

        return data;
    }

    /**
     * Reset the form fields.
     */
    reset() {
        for (let field in this.originalData) {
            this[field] = Array.isArray(this[field]) ? [] : this.originalData[field];
        }

        this.errors.clear();
    }

    /**
     * Clear a field.
     */
    clear(field) {
        if (this.hasOwnProperty(field)) {
            this[field] = Array.isArray(this[field]) ? [] : '';
        }
    }

    /**
     * Add a file to the form
     */
    addFile(event, key = null, multiFile = false, clearFile = true) {
        const formKey = key || event.target?.name || 'resume';
        if (formKey in this) {
            let file = getFileFromEvent(event, multiFile);

            if (file) {
                if (_.isArray(this[formKey])) {
                    if (_.isArray(file)) {
                        this[formKey] = this[formKey].concat(file);
                    } else {
                        this[formKey].push(file);
                    }
                } else {
                    this[formKey] = file;
                }
                this.errors.clear();
                if (event.target?.value && clearFile) {
                    event.target.value = '';
                }
            }
        }
        return this;
    }

    /**
     * Send a POST request to the given URL.
     * .
     * @param {string} url
     * @param {object} options
     */
    post(url, options = null) {
        return this.submit('post', url, options);
    }

    /**
     * Send a PUT request to the given URL.
     * .
     * @param {string} url
     * @param options
     */
    put(url, options = null) {
        return this.submit('put', url, options);
    }

    /**
     * Send a PATCH request to the given URL.
     * .
     * @param {string} url
     * @param options
     */
    patch(url, options = null) {
        return this.submit('patch', url, options);
    }

    /**
     * Send a DELETE request to the given URL.
     * .
     * @param {string} url
     * @param options
     */
    delete(url, options = null) {
        return this.submit('delete', url, options);
    }

    /**
     * Send a GET request to the given URL.
     * .
     * @param {string} url
     * @param options
     */
    get(url, options = null) {
        return this.submit('get', url, options);
    }

    /**
     * Check if the form includes a file
     *
     * @return {Boolean}
     */
    hasFile() {
        return _(this.data()).some(data => {
            if (_.isArray(data)) {
                return _(data).some(isFile);
            }

            return isFile(data);
        });
    }

    setUploadLimit(maxSize) {
        this.maxSize = maxSize;
        return this;
    }

    /**
     * Check if any of the files are too big
     *
     * @return {Boolean}
     */
    tooBig() {
        return _(this.data()).some(val => isTooBig(val, this.maxSize));
    }

    /**
     * Submit the form.
     *
     * @param method
     * @param {string} url
     * @param options
     */
    // eslint-disable-next-line complexity
    submit(method, url, options = null) {
        options = options || {};
        if (typeof options.clear === 'undefined') {
            options.clear = true;
        }
        if (typeof options.quiet === 'undefined') {
            options.quiet = false;
        }
        if (typeof options.scrollToFirstError === 'undefined') {
            options.scrollToFirstError = false;
        }
        if (typeof options.timeout === 'undefined') {
            options.timeout = 3000;
        }
        if (typeof options.redirect === 'undefined') {
            options.redirect = true;
        }

        let formData, hasFile = this.hasFile();

        if (hasFile) {
            if (this.tooBig()) {
                // Made the below change so that the 'TooBig' file be cleared out from the object.
                _(this.data()).each((val, key) => {
                    if (isTooBig(key, this.maxSize)) {
                        this[val] = null;
                    }
                });

                notify(trans('common.file-size', {
                    attribute: 'file',
                    max: this.maxSize,
                }), 'alert');
                return Promise.reject('file too big');
            }
            formData = new FormData();

            _(this.data()).each((value, key) => {
                if (key === 'customValues') {
                    formData.append(key, JSON.stringify(value));
                } else if (_.isArray(value)) {
                    _.each(value, val => {
                        if (_.isObject(val) && !isFile(val)) {
                            formData.append(`${key}[]`, JSON.stringify(val));
                        } else {
                            formData.append(`${key}[]`, val);
                        }
                    });
                } else if (_.isObject(value) && !isFile(value)) {
                    formData.append(key, JSON.stringify(value));
                } else if (_.isBoolean(value)) {
                    formData.append(key, +value);
                } else if (_.isNull(value) || _.isUndefined(value)) {
                    formData.append(key, '');
                } else {
                    formData.append(key, value);
                }
            });
            if (method !== 'post') {
                formData.append('_method', method.toUpperCase());
                method = 'post';
            }
        }

        formData = hasFile ? formData : this.data();
        if (this.formatDataCallback) {
            formData = this.formatDataCallback(formData);
        }

        if (!hasFile) {
            formData = JSON.stringify(formData);
        }

        if (method === 'get') {
            formData = formUrl('', this.data());
        }

        window.jwt.handleOutlookRequest();

        return $.ajax({
            method,
            url,
            processData: !hasFile,
            dataType: 'json',
            contentType: hasFile ? false : 'application/json',
            data: formData,
            success: (response) => {
                if (options.redirect && response.hasOwnProperty('redirect')) {
                    window.location.replace(response.redirect);
                }
                setTimeout(() => this.onSuccess(response, options.clear), 20);
            },
            error: (error) => {
                if (!options.quiet) {
                    this.onFail(
                        error, options.scrollToFirstError, options.timeout, options.useScrollTo, options.silencedErrors
                    );
                }
            },
        }).then(response => (
            response.notUpdated
            || response.meta
            || !_.has(response, 'data'))
            ? response
            : response.data
        )
        ;
    }

    /**
     * Handle a successful form submission.
     *
     * @param response
     * @param clear
     */
    onSuccess(response, clear) {
        if (clear) {
            this.reset();
        }
    }

    /**
     * Handle a failed form submission.
     *
     * @param error
     * @param scrollToFirstError
     */
    onFail(error, scrollToFirstError, timeout, useScrollTo, silencedErrors) {
        handleNalError(error, window.nal && window.nal.project, silencedErrors);

        if (+error.status === 422) {
            this.errors.record(error.responseJSON && error.responseJSON.errors, timeout);
            if (scrollToFirstError) {
                this.errors.scrollToFirst(useScrollTo, typeof scrollOptions === 'object' ? scrollToFirstError : null);
            }
            this.clear('password');
            this.clear('password_confirmation');
        }
    }

    /**
     * Make a get url with the data as parameters.
     *
     * @param {string} url
     */
    makeUrl(url) {
        return formUrl(url, this.data());
    }

    addElement(key, el, offset = 0) {
        this.errors.addElement(key, el, offset);
    }
}

export default Form;