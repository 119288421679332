// This file includes a list of cookies and similar technologies used on the site
// The objective of this file is better management of what should be allowed or
// prevented from being set based on the user's cookie preferences.

//// --------------------- ////
// KEEP IN ALPHABETICAL ORDER :)!!!
//// --------------------- ////

const functional = [
    // 'postRelease',
    'apiTokensHideExpired',
    'apiTokensSorting',
    'assignments-cardView',
    'assignmentsDashboardFilters',
    'assignmentPeopleFilters',
    'candidatesLayoutOption',
    'candidateProfileMinimize',
    'category-filter',
    'clientsLayoutOption',
    'clientsDashboardFilters',
    'companiesDashboardFilters',
    'companiesLayoutOption',
    'contactPanelFilters',
    'contactsDashboardFilters',
    'currencies',
    'currencies.timeout1',
    'dkim-popup',
    'draftedNotes',
    'emailOverlayPreference',
    'filterParams',
    'filtersOpenState',
    'formOverlayPreference',
    'hidden-tags',
    'hideMeetingsOverlay',
    'integration-sorting',
    'kpiLastViewed',
    'kpiGraphSettings',
    'large-interactions',
    'lists-sorting',
    'listsDashboardFilters',
    'listPeopleFilters',
    'meetings_box_view',
    'meetings_filter_own',
    'meetings_sort_by',
    'meetings-include-details',
    'meetings-include-name',
    'microsoft-graph-warning',
    'nal-consultants-warning',
    'nal-meetings-warning',
    'nal-remember',
    'nal-selected-bucket',
    'nullLastQBResults',
    'opportunitiesDashboardFilters',
    'opportunityPeopleFilters',
    'opportunities-graphs',
    'peopleLayoutOption',
    'pipeline-grouping-sort-order',
    'recent-tag',
    'searchLayoutOption',
    'tag-order',
    'targetContactsFilters',
    'targetContactsSorting',
    'targetContactsInclude',
    'task_meeting_filter_group',
    'tasks_box_view',
    'tasks_filter_own',
    'tasks_sort_by',
];

const essential = [
    '__cfduid',
    '__stripe_mid',
    '__stripe_sid',
    'connect-link',
    'cookie-preferences',
    'ezekia_session',
    'language',
    'laravel_token',
    'lastActivity',
    'nal-categories-boost-cache',
    'nal-categories-boost',
    'nal-admin-token',
    'nal53_session',
    'selected-currency',
    'selected-plan',
    'selected-services',
    'showSessionTimedOutMsg',
    'XSRF-TOKEN',
];

export {
    functional,
    essential,
};