// This file contains js pertinent to all builds ->
// the main site, client portal, executives, partner, home page and outlook.

import _ from 'lodash';
import Cookie from 'js-cookie';
import moment from 'moment';
import 'moment/dist/locale/en-ca';
import 'moment/dist/locale/en-au';
import 'moment/dist/locale/en-gb';

import '@js/core/handleSessionTimeout.js';
import EzekiaLocalStorage from './core/EzekiaLocalStorage.js';
import Analytics from './core/cookies/analytics.js';
import cookieConvert from '@js/core/cookieConvert.js';
import auth from './core/authorize.js';
import jwt from './core/auth/jwt.js';
import Form from '@js/core/Form.js';
import interactsWithModel from '@js/nal/vue-mixins/interactsWithModel.js';

import './core/helpers.js';

// Setting and Getting local storage items that were originally cookies
window.EzekiaLocalStorage = EzekiaLocalStorage;

// Set up analytics tracking
window.Analytics = Analytics;
window.Analytics.setup();

// Helper libraries
window._ = _;
window.Cookie = Cookie;
window.moment = moment;
window.setCountry = (country) => {
    moment.locale(nal.locale || 'en');
    if (nal.locale === 'en') {
        if (country === 'US') {
            moment.locale('en');
        } else if (country === 'CA') {
            moment.locale('en-ca');
        } else if (country === 'AU' || country === 'NZ') {
            moment.locale('en-au');
        } else {
            moment.locale('en-gb');
        }
    }
    window.nal.amTime = moment.localeData()._longDateFormat.LT.substr(-1) === 'A';
};

cookieConvert();

window.Form = Form;
window.auth = window.authorize = auth;
window.jwt = jwt;

// global function
window.setVueObject = (object, key, value) => {
    Object.assign(object, { [key]: value });
};

window.deleteKeyFromVueObject = (object, key) => {
    delete object[key];
};

window.deleteIndexFromVueArray = (array, index) => {
    array.splice(index, 1);
};

window._mixins = {
    interactsWithModel,
};

// Set api version with 'v' prefix
window.setVueObject(window.nal, 'apiVersion', `v${window.nal.apiVersion}`);

/*
When a new deployment occurs, the hosting service may delete the assets from previous deployments.
As a result, a user who visited your site before the new deployment might encounter an import error.
This error happens because the assets running on that user's device are outdated
and it tries to import the corresponding old chunk, which is deleted.
*/
window.addEventListener('vite:preloadError', () => {
    window.location.reload();
});

Promise.prototype.always = Promise.prototype.finally;