const EzekiaLocalStorage = {
    set: (key, data) => {
        setToLocalStorage(key, data);
    },
    get: (key) => {
        return localStorage.getItem(key);
    },
    remove: (key) => {
        localStorage.removeItem(key);
    },
};

import { isEnabled } from '@js/core/cookies/cookiePreferences.js';

import { essential, functional } from '@js/core/storageList.js';

function setToLocalStorage(key, data) {
    if (!~_.indexOf(essential, key)) {
        if (!isEnabled('functional') && ~_.indexOf(functional, key)) {
            return ;
        }
    }

    localStorage.setItem(key, data);
}

export default EzekiaLocalStorage;