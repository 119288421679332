import { policyBuilder } from './auth/policies';
import { getPermissionsFor } from './auth/helpers.js';

window.policies = policyBuilder();

function getModel(type, model) {
    if (model && _.isInteger(model)) {
        return _.chain(window.nal).get(type + (type !== 'contacts' ? '.data' : '')).find(['id', model]).value();
    }
    return model;
}

function checkPermissions(user, type, action) {
    const permissions = getPermissionsFor(user);
    let permission = _(permissions).get([type, action]);
    if (_.isBoolean(permission)) {
        return permission;
    }
    permission = _(permissions).get(action);
    if (_.isBoolean(permission)) {
        return permission;
    }
    if (process.env.NODE_ENV !== 'production' && window.packageBuild !== 'cp') {
        throw new Error(
            'There is no policy defined for action: ' + action + ' and type: ' + type
        );
    }
    return false;
}

var authorize = function (action, type, model, extra, user) {
    user = user || window.nal.user;
    model = getModel(type, model);
    if (window.policies[type] && window.policies[type][action]) {
        return window.policies[type][action](model, user, extra);
    }
    return checkPermissions(user, type, action);
};

export default authorize;