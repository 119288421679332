import { functional } from '@js/core/storageList.js';

function optIn() {
    // nothing at the moment.
}

function optOut() {
    removeCookiesAndStorage();
}

function removeCookiesAndStorage() {
    // including undefined and empty strings that may have been
    // added to local storage due to uncaught dev issues
    let storageList = _.concat(functional, ['undefined', '']);
    _.forEach(storageList, key => {
        Cookie.get(key) ? Cookie.remove(key) : EzekiaLocalStorage.remove(key);
    });
}

const Functional = {
    optIn,
    optOut,
};

export default Functional;