function isRegistered(user) {
    return user && user.page === '/';
}

function isRegisteredSearcher(user) {
    return user && (user.role === 'search') && isRegistered(user);
}

function isRegisteredExecutive(user) {
    return user && (user.role === 'executive') && isRegistered(user);
}

function isApprovedPartner(user, company) {
    return user && (user.role === 'partner') && company && company.approved;
}

function authorizeTargetingScript(user, company = null) {
    return !user || isRegisteredSearcher(user) || isRegisteredExecutive(user) || isApprovedPartner(user, company);
}

function exists(key) {
    return !!document.getElementById(key);
}

const isLive = process.env.NODE_ENV === 'production';
const hostname = window.location.hostname;
let regex = new RegExp('((test[0-9]?.)|(demo.)|([a-z]+.test[0-9]?.)|(partners.)|(client.))');
const baseHostname = (hostname).replace(regex, '');
const isEzekia = (hostname).match(new RegExp('[a-z]?.?ezekia.com'));

export {
    isLive,
    hostname,
    baseHostname,
    isEzekia,
    authorizeTargetingScript,
    exists,
};