import Cookie from 'js-cookie';

let cookieConvert = () => {
    const cookieKeys = [
        'nal-remember',
        'nal-consultants-warning',
        'candidate-sorting',
        'recent-tag',
        'tag-order',
        'hidden-tags',
        'card-view',
        'grouping-view',
        'user-contacts',
        'companyStatusFilter',
        'nal-selected-bucket',
        'leads-graphs',
        'filter-own',
        'opportunities-graphs',
        'tasks_filter_own',
        'assignments-cardView',
        'clients-cardView',
        'companies-cardView',
        'opportunities-cardView',
        'large-interactions',
        'dkim-popup',
        'nal-selected-bucket',
        'nal-contact-warning',
        'nal-selected-bucket',
        'assignments-filterMine',
        'clients-filterMine',
        'companies-filterMine',
        'opportunities-filterMine',
        'clientFilters',
        'assignmentFilters',
        'meetings-include-details',
        'nal-meetings-warning',
        'meetings_filter_own',
        'meetings_sort_by',
        'meeting_box_view',
        'tasks_filter_own',
        'tasks_sort_by',
        'tasks_box_view',
        'assignments_filter_own',
        'clients_filter_own',
        'companies_filter_own',
        'opportunities_filter_own',
        'task_meeting_filter_group',
        'assignmentFilters',
        'companyFilters',
        'clientFilters',
        'leadsFilters',
        'leadsFiltersNew',
        'filterParams',
        'contactFilters',
        'opportunityFilters',
        'nal-admin-token',
        'assignmentFiltersNoCustom',
        'clientFiltersNoCustom',
        'companyFiltersNoCustom',
        'opportunityFiltersNoCustom',
        'statusAssignmentFilter',
        'contactFiltersNew',
        'insideFilters',
        'insideFiltersNew',
        'undefined',
    ];

    _.forEach(cookieKeys, cookieKey => {
        let data = Cookie.get(cookieKey);

        if (typeof data === "undefined") {
            return;
        }

        window.EzekiaLocalStorage.set(cookieKey, data);
        Cookie.set(cookieKey, '', { expires: -1 });
    });
};

export default cookieConvert;