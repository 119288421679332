import { isEnabled } from '@js/core/cookies/cookiePreferences.js';
import { isLive, baseHostname, isEzekia, exists } from '@js/core/cookies/helpers';

const gaCookies = ['_ga', '_gid', '_gat'];
// Google Analytics
const gaID = isEzekia ? 'UA-145149352-1' : 'UA-55428899-1';
// Google Tag tracking id
const gtID = isEzekia ? 'GTM-PVDPJC5' : 'GTM-5PQHKXQ';
const domain = (['.', baseHostname]).join('');

// run script to enable google analytics tracking
function setupGoogleAnalytics() {
    if (exists('gAnalytics')) {
        return;
    }
    (function (i, s, o, g, r, a, m) {
        i.GoogleAnalyticsObject = r; i[r] = i[r] || function () {
            (i[r].q = i[r].q || []).push(arguments);
        }, i[r].l = 1 * new Date(); a = s.createElement(o),
        m = s.getElementsByTagName(o)[0]; a.id = 'gAnalytics'; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m);
    })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

    createTracking();
}

// run script to enable google tag tracking
function setupGoogleTag() {
    if (exists('gTag')) {
        return;
    }
    (function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
            'gtm.start':
                new Date().getTime(), event: 'gtm.js',
        }); var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.id = 'gTag'; j.async = true; j.src
                = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', gtID);
}

// run script to enable RUM 24x7 analytics/tracking
function setupRUM() {
    (function (w, d, s, r, k, h, m) {
        if (w.performance && w.performance.timing && w.performance.navigation && !w.s247r) {
            w[r] = w[r] || function () {
                (w[r].q = w[r].q || []).push(arguments);
            };
            h = d.createElement('script'); h.async = true; h.setAttribute('src', s + k);
            d.getElementsByTagName('head')[0].appendChild(h);
            (m = window.onerror), (window.onerror = function (b, c, d, f, g) {
                m && m(b, c, d, f, g), g || (g = new Error(b)), (w[r].q = w[r].q || []).push(['captureException', g]);
            });
        }
    })(window, document, '//static.site24x7rum.com/beacon/site24x7rum-min.js?appKey=', 's247r', '9d2e08ba26b34f70a47fc60f10144d1a');
}

function setup() {
    if (isEnabled('analytics')) {
        try {
            setupGoogleAnalytics();
            if (isLive) {
                setupGoogleTag();
                setupRUM();
            }
        } catch (error) {
            // if the error is due to adBlocker, ignore it
            if (error.status === 0) {
                return null;
            }
            throw error;
        }
    }
}

function createTracking() {
    ga('create', gaID, 'auto');
    if (window.Sentry && typeof ga === 'undefined') {
        window.Sentry.withScope(scope => {
            scope.setExtra(
                'cookie-preferences',
                JSON.parse(EzekiaLocalStorage.get('cookie-preferences'))
            );

            Sentry.captureMessage('Debug: ga is not defined');
        });
    }
    send();
}

function checkGaSetup() {
    if (isEnabled('analytics') && typeof ga === 'undefined') {
        setup();
    }
}

function send(data = null) {
    if (!data) {
        data = 'pageview';
    }
    ga('send', data);
}

function setPage(path, user, extra = null) {
    checkGaSetup();
    if (!isEnabled('analytics')) {
        return;
    }
    ga('set', 'page', path.path);
    if (user) {
        ga('set', 'userId', user.id);
    }

    send(extra);
}

function optOut() {
    window['ga-disable-' + gaID] = true;
    removeCookies();
    removeScripts();
}

function optIn() {
    if (isEnabled('analytics')) {
        window['ga-disable-' + gaID] = false;
        setup();
    }
}

function removeCookies() {
    _.forEach(gaCookies, function (key) {
        Cookie.remove(key, { path: '', domain: domain });
    });
}

function removeScripts() {
    let script = ['gTag', 'gAnalytics'];
    script.forEach(function (script) {
        let element = document.getElementById(script);
        if (element) {
            element.remove();
        }
    });
}

const Analytics = {
    setup,
    setPage,
    optOut,
    optIn,
};

export default Analytics;
