import { reactive, createApp } from 'vue';
import AppGlobalMixin from '../vue-mixins-global/app-mixin.js';
import useClipboard from 'vue-clipboard3';

if (!String.prototype.singular) {
    String.prototype.singular = function () {
        /* return if the string is empty */
        if (!this.length) {
            return this;
        }
        /* Prevents attempted singularisation of words ending with 'ss' */
        if ((/s{2}$/).test(this)) {
            return this;
        }
        if (this === 'people') {
            return 'person';
        }
        return this.replace(/(ie)?(s)\b/, function (match, p1) {
            return p1 ? 'y' : '';
        });
    };
}

if (!String.prototype.plural) {
    String.prototype.plural = function () {
        /* return if the string is empty */
        if (!this.length) {
            return this;
        }
        if (this === 'person') {
            return 'people';
        }
        return this.replace(/y\b/, 'ie') + 's';
    };
}

if (!String.prototype.capitalize) {
    String.prototype.capitalize = function () {
        /* return if the string is empty */
        if (!this.length) {
            return this;
        }
        return this[0].toUpperCase() + this.slice(1);
    };
}

window.optional = (obj) => {
    if (obj && typeof obj === 'object') {
        return obj;
    }
    return {};
};
window.trans = (key, replace = {}, locale = null) => {
    if (typeof key === 'undefined') {
        return window.Lang;
    }

    return window.Lang.get(key, replace, locale);
};

window.trans_choice = (key, count, replace = {}, locale = null) => {
    return window.Lang.choice(key, count, replace, locale);
};

window.NotificationStore = {
    state: reactive([]), // here the notifications will be added

    addNotification(notification) {
        if (notification.once && _(this.state).some(item => _(item).omit('id').isEqual(notification))) {
            return;
        }
        notification.id = Math.random();
        this.state.push(notification);
    },
    removeNotification(notification) {
        let index = this.state.indexOf(notification);
        if (~index) {
            this.state.splice(index);
        }
    },
    clear() {
        this.state.splice(0, this.state.length);
    },
};

window.notify = (payload, type = 'success', timeout = true, delay = 3000, once = true, html = false) => {
    let notification = { payload, type, timeout, delay, once, html };
    window.NotificationStore.addNotification(notification);
    return notification;
};

window.notifyHtml = (payload, type = 'success', timeout = true, delay = 3000, once = true) => {
    window.notify(payload, type, timeout, delay, once, true);
};

window.notifyMachineLearning = (payload, timeout = true, delay = 3000, once = true) => {
    window.notify(payload, 'dataenrichment', timeout, delay, once, true);
};

window.noop = () => { };

Array.prototype.updateOrPush = function (item, callback, key, unshift) {
    let predicate;
    if (key === false) {
        predicate = item;
    } else {
        key = key || 'id';
        predicate = [key, _(item).get(key)];
    }
    const index = _.findIndex(this, predicate);
    if (index !== -1) {
        callback.bind(this)(this[index], index);
    } else {
        const method = unshift ? 'unshift' : 'push';
        this[method](item);
    }
    return this;
};

Array.prototype.spliceOrPush = function (item, key, unshift) {
    return this.updateOrPush(item, function (duplicate, index) {
        this.splice(index, 1, item);
    }, key, unshift);
};

Array.prototype.nalRemove = function (item) {
    let index = this.indexOf(item);
    if (~index) {
        return this.splice(index, 1);
    }
};

if (!String.prototype.stripTags) {
    String.prototype.stripTags = function () {
        return this.replace(/(<([^>]+)>)/ig, '');
    };
}

window.makeOutsideLink = function (url) {
    let regex = /^(https?:)?\/\//;
    if (regex.test(url)) {
        return url;
    }
    return '//' + url;
};

window.handleEscapeListener = (callback) => {
    const handler = e => {
        var isEscape = false;
        if ('key' in e) {
            isEscape = (e.key === 'Escape' || e.key === 'Esc');
        } else {
            isEscape = (e.keyCode === 27);
        }
        if (isEscape) {
            callback(e);
        }
    };
    document.addEventListener('keyup', handler);
    return () => {
        document.removeEventListener('keyup', handler);
    };
};

window.mergeObject = (obj, src) => {
    const valuesToSet = [];
    const merged = _.mergeWith(obj, src, (objValue, srcValue, key, nestedObj) => {
        if (key && _.isUndefined(objValue)) {
            valuesToSet.push([nestedObj, key]);
        }
        if (_.isArray(srcValue) || _.isString(objValue)) {
            return srcValue;
        }
        if (_.isPlainObject(objValue)) {
            return window.mergeObject(objValue, srcValue);
        }
        return srcValue || objValue;
    });

    valuesToSet.forEach(([obj, key]) => {
        const value = obj[key];
        window.deleteKeyFromVueObject(obj, key);
        window.setVueObject(obj, key, value);
    });

    return merged;
};

// CustomEvent Polyfill for IE
function CustomEventPolyfill(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: null };
    var evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
}

CustomEventPolyfill.prototype = window.Event.prototype;

if (typeof window.CustomEvent !== 'function') {
    window.CustomEvent = CustomEventPolyfill;
}
// CustomEvent Polyfill ends

// Create a custom event to close modal from deep child components.
const modalCloseEvent = new CustomEvent('close-modal', {
    bubbles: true,
});

window.triggerModalCloseEvent = () => {
    document.dispatchEvent(modalCloseEvent);
};

window.formUrl = function (url, data) {
    let fullUrl = url ? url + '?' : '';
    let properties = [];
    for (let key in data) {
        let value = data[key];

        if (typeof value === 'object') {
            let isArray = _.isArray(value);

            _.forEach(value, (item, index) => {
                properties.push(`${key}[${isArray ? '' : index}]=${item}`);
            });
            continue;
        }
        if (typeof value === 'boolean') {
            value = +value;
        }
        if (!_.isNil(value) || value !== '') {
            properties.push(key + '=' + value);
        }
    }
    return fullUrl + properties.join('&');
};

window.createComponent = function (component, props) {
    const componentInstance = createApp(
        component,
        { ...props }
    );
    componentInstance.mixin(AppGlobalMixin);
    componentInstance.provide('toClipboard', useClipboard().toClipboard);
    return componentInstance;
};
