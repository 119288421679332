import { markRaw } from 'vue';
import handleNalError from './../core/handleNalError.js';

export default {
    data() {
        return {
            nal: window.nal,
            moment,
        };
    },
    inject: ['toClipboard'],
    methods: {
        async copyToClipboard(text, successMessage) {
            try {
                await this.toClipboard(text);
                notify(successMessage, 'success');
            } catch (error) {
                notify(error.message, 'alert');
            }
        },
        trans(...args) {
            return window.trans(...args);
        },
        trans_choice(...args) {
            return window.trans_choice(...args);
        },
        optional(...args) {
            return window.optional(...args);
        },
        call(method, url, data = null, options = {}) {
            window.jwt.handleOutlookRequest();

            return $.ajax({
                method,
                url: url,
                data: data && JSON.stringify(data),
                contentType: 'application/json',
            }).done(response => {
                if (response?.redirect) {
                    return window.location.replace(response.redirect);
                }
            }).fail(error => {
                handleNalError(error, window.nal?.project, options.silencedErrors);
            });
        },
        get(url, data) {
            return this.call('get', url, data);
        },
        delete(url, data) {
            return this.call('delete', url, data);
        },
        post(url, data) {
            return this.call('post', url, data);
        },
        put(url, data, options) {
            return this.call('put', url, data, options);
        },
        auth(...args) {
            return window.auth(...args);
        },
        closeCurrentModal() {
            if (this.nal.modal.backtrace.length) {
                this.nal.modal.isBacktrace = true;
                this.createModal({ data: this.nal.modal.backtrace.pop(), isBacktrace: false });
                return;
            }
            this.nal.modal.showModal = false;
        },
        closeAllModals() {
            this.nal.modal.backtrace = [];
            this.nal.modal.showModal = false;
        },
        handleModalBacktrace() {
            if (!this.nal.modal.isBacktrace) {
                if (this.nal.modal.showModal) {
                    let oldModal = _.clone(this.nal.modal);
                    oldModal.isBacktrace = true;
                    this.nal.modal.backtrace.push(oldModal);
                }
            }

            if (this.clearBacktrace) {
                this.nal.modal.backtrace = [];
            }
        },
        setModalComponent(data) {
            if (_.isObject(data.component)) {
                let componentNameIsComponent = false;
                let componentName;

                if (data.component.name && data.component.name === 'component') {
                    componentNameIsComponent = true;
                    componentName = 'modal-' + (new Date).getTime();
                }

                if (componentNameIsComponent) {
                    this.nal.modal.component = componentName;
                } else {
                    this.nal.modal.component = markRaw(data.component);
                }
            } else {
                this.nal.modal.component = data.component;
            }
        },
        setModalData(data) {
            this.nal.modal.data = data.data || {};
            this.nal.modal.style = data.style || {};
            this.nal.modal.colorClass = data.colorClass || 'u-primary-color';
            this.nal.modal.hasNoPadding = data.hasNoPadding || false;
            this.nal.modal.document = data.document || false;
            this.nal.modal.on = data.on || {};
            this.nal.modal.isBacktrace = false;
            this.nal.modal.blur = _(data).has('blur') ? data.blur : true;
            this.nal.modal.showModal = true;
            this.nal.modal.scrollTop = data.scrollTop ?? undefined;
            if (data.key) {
                this.nal.modal.key = data.key;
            } else {
                this.nal.modal.key = 'unique' + this.nal.modal.uniqueId;
                this.nal.modal.uniqueId++;
            }
        },
        createModal(data) {
            this.handleModalBacktrace(data.data);
            this.setModalComponent(data.data);
            this.setModalData(data.data);
        },
        createInteractions(data) {
            this.nal.interactions.component = data.component;
            this.nal.interactions.data = data.data || {};
            this.nal.interactions.style = data.style || {};
            this.nal.interactions.innerStyle = data.innerStyle || {};
            this.nal.interactions.selectedTab = data.selectedTab || {};
            this.nal.interactions.showInteractions = true;
        },
        closeInteractions() {
            this.nal.interactions.showInteractions = false;
        },
    },
};