const ALWAYS = 'always';
const NEVER = 'never';
const SHARED = 'shared';
const PUBLIC = 'public';

import _ from 'lodash';

function isConsultant(user, model) {
    return _(model).get('owner.id') === user.id
        || _.some(_.get(model, 'consultants.users', []), ['id', user.id]);
}

function isOfficeGroupUser(user, model) {
    let user_ids = _(model).chain().get('consultants.office_groups', []).flatMap((officeGroup) => {
        return officeGroup.user_ids;
    }).uniq().value();
    return _.includes(user_ids, user.id);
}

function isRegionUser(user, model) {
    let user_ids = _(model).chain().get('consultants.regions', []).flatMap((region) => {
        return region.user_ids;
    }).uniq().value();
    return _.includes(user_ids, user.id);
}

function isPublic(user, type, model) {
    return !_(model).has('private') || !model.private;
}

function isCategory99(firm) {
    return _(firm).get('category', 1) === 99;
}

function hasPermission(permission, model, user, type) {
    if (_.isBoolean(permission)) {
        return permission;
    }
    if (permission === ALWAYS) {
        return true;
    }
    if (permission === SHARED) {
        if (!model && type === 'compliance') {
            return true;
        }
        return isConsultant(user, model) || isOfficeGroupUser(user, model) || isRegionUser(user, model);
    }
    if (permission === PUBLIC) {
        return isPublic(user, type, model) || isConsultant(user, model) || isOfficeGroupUser(user, model) || isRegionUser(user, model);
    }
    return false;
}

function getPermissionGroupFor(user) {
    if (!user) {
        return null;
    }
    if (!_.isObject(user.permissionGroup)) {
        let group = _.find(window.nal.firm.permission_groups, ['id', user.permissionGroup]);
        user.permissionGroup = group || user.permissionGroup;
    }
    return user.permissionGroup;
}

function getPermissionsFor(user) {
    let permissions = getPermissionGroupFor(user);
    return permissions && permissions.allPermissions;
}

function getPermissionFor(user, type, action) {
    return getPermissionsFor(user)[type][action];
}

function allowed(action, type, model, user) {
    return hasPermission(getPermissionsFor(user)[type][action], model, user, type);
}

function buildAuthFunctions(type) {
    // Pre populate the type and action arguments in the allowed function
    function typeCan(action) {
        return _.partial(allowed, action, type);
    }

    const authFunctions = {};

    // Loop through all the permissions for that type and create a function from
    // the value. If the value is a boolean the function just returns that
    // boolean (_.constant). If not we create the modified hasPermission
    // function for that action.
    let permissions = nal.permissions;
    if (permissions) {
        _.keys(permissions[type]).forEach(action => {
            authFunctions[_.camelCase(action)] = typeCan(action);
        });
    }

    return authFunctions;
}

export {
    allowed,
    hasPermission,
    buildAuthFunctions,
    getPermissionFor,
    getPermissionsFor,
    getPermissionGroupFor,
    isCategory99,
};
