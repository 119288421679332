export default function handleNalError(error, project, silencedErrors) {
    if (error instanceof Error) {
        // If the error is not a request failure we need to rethrow it so sentry
        // can handle it.
        throw error;
    }
    if (+error.status === 0 && error.statusText !== 'abort') {
        window.notify(trans('common.alert.connection'), 'alert');
        throw error;
    }
    if (+error.status === 502) {
        window.notify(trans('common.alert.502'), 'alert');
    }
    if (+error.status === 500) {
        window.notify(trans('common.alert.500'), 'alert');
    }
    if (+error.status === 413) {
        window.notify(trans('common.alert.413'), 'alert');
    }
    if (project === 'nal') {
        if (+error.status === 419 || +error.status === 401) {
            window.location.href = '/login';
        }
        if (catchError(error.status, 403, silencedErrors)) {
            window.notify(trans('common.alert.403'), 'alert');
        }
    }
    if (project === 'home-ezekia' || project === 'home-nal') {
        if (+error.status === 419) {
            window.location.reload();
        }
    }
    if (project === 'outlook') {
        if (+error.status === 419 || +error.status === 401) {
            if (error.responseJSON?.error === 'concurrent') {
                window.location.href = '/outlook/home/#/logout-concurrent';
            } else {
                window.location.href = '/outlook/home/#/login';
            }
        }
    }
    if (window.packageBuild === 'cp') {
        if (+error.status === 419 || +error.status === 401 && error.responseJSON?.error !== 'concurrent') {
            window.nal.user.true_role === 'search'
                ? window.location.replace(window.nal.baseUrl + '/login')
                : window.location.reload();
        }
        if (+error.status === 404 && error.responseJSON?.archived) {
            window.notify(trans('client-portal.auth.alerts.archived'), 'alert');
        }
    }
}

function catchError(errorStatus, status, silencedErrors = []) {
    const silenceError = silencedErrors.includes(errorStatus);
    return +errorStatus === status && !silenceError;
}